import React, { useContext } from "react"

import Layout from "../components/organisms/Layout/Layout"
import Seo from "../components/organisms/Seo/Seo"
import Container from "../components/atoms/Container/Container"
import * as styles from './find-us.module.css'
import GlobalContent from "../components/atoms/GlobalContent/GlobalContent"
import Breadcrumbs from '../components/atoms/Breadcrumbs/Breadcrumbs'
import ContentfulContext from "../context/ContentfulProvider"
import { get } from "lodash"
// import PageTitle from '../components/atoms/PageTitle/PageTitle'

const FindUs = () => {
  const contentful = useContext(ContentfulContext);
  const translations = get(contentful, 'translationData', {});
  
  const ThisScript = () => {
    if (typeof document !== 'undefined') {
      return (
        <script data-storemapper-start='2022,05,13'
        data-storemapper-id='15772'>
        {(function() {var script = document.createElement('script');
          script.type  = 'text/javascript';script.async = true;
          script.src = 'https://www.storemapper.co/js/widget-3.min.js';
          var entry = document.getElementsByTagName('script')[0];
          entry.parentNode.insertBefore(script, entry);}
        ())}
        </script>
      )
    }

    return null;
  };

  return (
      <Layout>
          <Seo title="Find Us" />
          <Container size="medium">
            <Breadcrumbs crumbs={[{label: get(translations, 'findUs.title', 'Find Us')}]} />
            {/* <PageTitle title="In-Store Locations" /> */}
            <div className={styles.body}><GlobalContent name={`Find Us Page`} /></div>
            <div className={styles.mapDisplay}>
              <div id='storemapper'>
                <p>{get(translations, 'storeLocatorLoading.title', 'Store Locator is loading from')} <a href='https://www.storemapper.co'>Storemapper</a>...</p>
              </div>
            </div>
            <ThisScript />
          </Container>
      </Layout>
  )
}

export default FindUs